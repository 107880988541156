
import {Options, Vue} from "vue-class-component";
import store from "@/store";
import { openFileInNewTab } from "@/commons/index";
import moment from "moment";

@Options({
  components: {
  }
})
export default class DocumentsHistoryDialog extends Vue {
   isLoading : any= true
   get toggleShow(){
  return store.state.orderModule.showDocumentsHistory
}

  get reference() {
    switch (this.currentRoute) {
      case "order-edit":
        return store.state.orderModule.order?.reference;
      case "TaskDetail":
        return store.state.taskModule.order?.reference;
    }
    return "-";
  }
  get orderId() {
    switch (this.currentRoute) {
      case "order-edit":
        return store.state.orderModule.order?.resourceUid;
      case "TaskDetail":
        return store.state.taskModule.order?.resourceUid;
    }
    return "-";
  }
   getDateFormat(date?: any){
     return moment(date, 'YYYY-MM-DD HH:mm:ss.S').format('DD/MM/YYYY HH:mm') 
  }

  toggleShowControls() {
    return store.dispatch('orderModule/toggleShowDocumentsHistory')
  }

  get currentRoute() {
    return this.$router.currentRoute.value.name;
  }
   get selectedObject() {
       return store.state.orderModule && store.state.orderModule.deletedDocuments ? store.state.orderModule.deletedDocuments : []
  }

  documentType(value: any) {
    let relations : any = [];
     if (value?.relations) {
         relations = JSON.parse(value.relations);
     }
       return relations
       && relations[0]
       && relations[0].qualifier
       && relations[0].qualifier.rawValue
       ? relations[0].qualifier.rawValue
       : ""
  }

  async downloadDocument(document: any) {
      await store.dispatch("taskModule/uploadDocumentModule/downloadFile", {
            request: {resourceUid:document.file_resourceuid},
            callback: this.downloadDocumentResponse,
          });
  }

      
  downloadDocumentResponse(response: any) {
      if (response) {
          const {content} = response;
          const linkSource = `data:${response.format};base64,${content}`;
          const downloadLink = document.createElement("a");
          downloadLink.href = linkSource;
          downloadLink.download = response.relations.items[0].qualifier.rawValue.replace(/_/g, ' ');
            downloadLink.click();
      }
  }

  async printDocument(document: any) {
    const downloadDocument :any = await new Promise((resolve) => {
              store.dispatch("taskModule/uploadDocumentModule/downloadFile", {
                callback: (res:any) => {
                  resolve(res);
                },
                request: {resourceUid:document.file_resourceuid}
              });
          });
    if (downloadDocument.content) {
          this.printDocumentResonse(downloadDocument)
    }
  }


  printDocumentResonse(documentUploadResponse: any) {
    if (documentUploadResponse) {
      const {content, format} = documentUploadResponse;
      const byteCharacters = atob(content);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], {type: format});

            const blobUrl = URL.createObjectURL(blob);

            // Crée dynamiquement un iframe
            const iframe = document.createElement('iframe');
            iframe.style.display = 'none';
            document.body.appendChild(iframe);

            iframe.src = blobUrl;

            iframe.onload = function() {
                iframe?.contentWindow?.focus();
                iframe.contentWindow?.print();

            };
    }
  }

 async viewDocument (document: any) {
    const downloadDocument :any = await new Promise((resolve) => {
              store.dispatch("taskModule/uploadDocumentModule/downloadFile", {
                callback: (res:any) => {
                  resolve(res);
                },
                request: {resourceUid:document.file_resourceuid}
              });
    });
  if (downloadDocument.content) {
      const { format, content } = downloadDocument
      openFileInNewTab({ name: downloadDocument.originalFileName, mimeType: format, base64: content });
  } 
}

  async created(){
  this.isLoading = true
  await store.dispatch("orderModule/getOrderDeletedDocuments", {
      filter: {
        orderResourceUid: this.orderId
      },
    });
      this.isLoading = false
  }
}
