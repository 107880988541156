import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "row mb-4 font-weight-bold" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = {
  key: 0,
  class: "font-weight-bold"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_checkbox = _resolveComponent("q-checkbox")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('demand.tabs.conditions')), 1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (!_ctx.orderConditions?.length)
          ? (_openBlock(), _createElementBlock("label", _hoisted_4, _toDisplayString(_ctx.$t("order.orderConditions.NoConditionFound")), 1))
          : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.orderConditions, (condition) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "col-md-4 mb-4",
                key: condition.id
              }, [
                _createVNode(_component_q_checkbox, {
                  modelValue: condition.checked,
                  "onUpdate:modelValue": ($event: any) => ((condition.checked) = $event),
                  label: _ctx.$t('order.orderConditions.list.'+condition?.condition?.resourceUid),
                  dense: "",
                  disable: "",
                  class: "font-size-12"
                }, null, 8, ["modelValue", "onUpdate:modelValue", "label"])
              ]))
            }), 128))
      ])
    ])
  ], 64))
}