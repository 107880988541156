
import store from "@/store";
import { Options, Vue } from "vue-class-component";
import LabelAndValue from "@/components/general/LabelAndValue.vue";
import {globalConfig} from "@/utils";
import i18n from "@/i18n";

@Options({
  components: { LabelAndValue },
  watch: {
    "$store.state.orderModule.variablesVisible"(newValue) {
      this.visible = newValue;
    },
    async "$store.state.orderModule.statusHistoryVariableSelected"(newValue) {
      if (newValue) {
        this.rows = [
          {"label":  i18n.global.t('task.historiesStep.headers.variables.orderReference'), "value": newValue.orderReference},
          {"label":  i18n.global.t('task.historiesStep.headers.variables.orderStatus'), "value": i18n.global.t(`order.picklist.status.${newValue.orderStatus}`)},
          {"label":  i18n.global.t('task.historiesStep.headers.variables.orderPhase'), "value": i18n.global.t(`task.actions.${newValue.taskDefinition}`)},
          {"label":  i18n.global.t('task.historiesStep.headers.variables.initialDeliveryDate'), "value": newValue.initialDeliveryDate},
          {"label":  i18n.global.t('task.historiesStep.headers.variables.provisionalDeliveryDate'), "value": newValue.provisionalDeliveryDate},
          //{"label":  i18n.global.t('task.historiesStep.headers.variables.customerName'), "value": newValue.customerName},
          //{"label":  i18n.global.t('task.historiesStep.headers.variables.customerType'), "value": i18n.global.t(`enum.party.type.${newValue.customerType}`)}
          {"label":  i18n.global.t('task.historiesStep.headers.variables.comment'), "value": newValue.comment}]
      } else {
        this.rows = []
      }
    },
  },
})
export default class StatusOrderHistory extends Vue {
  visible: boolean = false;
  rows: any = []

  pagination = {
    rowsPerPage: 20
  };

  closeStatusHistory() {
    store.dispatch("orderModule/setOrderVariablesVisible", false);
  }

  get columns() {
    return globalConfig.table_config.statusHistoryCols;
  }

  get statusHistoryVariableSelected() {
    console.log(store.state.orderModule.statusHistoryVariableSelected)
    return store.state.orderModule.statusHistoryVariableSelected;
  }

}
