
import { Options, Vue } from "vue-class-component";
import { formatDate } from "@/utils/configuration/formatters-config";
import { globalConfig } from "@/utils";

@Options({
  components: { },
  props: ["histories"],
})
export default class OrderHistoryPanel extends Vue {
  histories?: [];

  columns = globalConfig.table_config.orderHistoryTableCols.histories;
}
