
import {Options, Vue} from "vue-class-component";
import { openFileInNewTab } from "@/commons/index";
import moment from "moment";
import store from "@/store";

@Options({
  methods: {},
  components: {},
  props: [""],
})
export default class OrderSummaryDocuments extends Vue {
 
  isLoading = true


getDateFormat(date?: any){
     return moment(date, 'YYYY-MM-DD HH:mm:ss.S').format('DD/MM/YYYY HH:mm') 
}


get selectedObject() {
       return store.state.orderModule && store.state.orderModule.deletedDocuments ? store.state.orderModule.deletedDocuments : []
}

documentType(value: any) {
    let relations : any = [];
     if (value?.relations) {
         relations = JSON.parse(value.relations);
     }
       return relations
       && relations[0]
       && relations[0].qualifier
       && relations[0].qualifier.rawValue
       ? relations[0].qualifier.rawValue
       : ""
}

async downloadDocument(document: any) {
      await store.dispatch("taskModule/uploadDocumentModule/downloadFile", {
            request: {resourceUid:document.file_resourceuid},
            callback: this.downloadDocumentResponse,
          });
}

      
downloadDocumentResponse(response: any) {
      if (response) {
          const {content} = response;
          const linkSource = `data:${response.format};base64,${content}`;
          const downloadLink = document.createElement("a");
          downloadLink.href = linkSource;
          downloadLink.download = response.relations.items[0].qualifier.rawValue.replace(/_/g, ' ');
            downloadLink.click();
      }
}

async printDocument(document: any) {
    const downloadDocument :any = await new Promise((resolve) => {
              store.dispatch("taskModule/uploadDocumentModule/downloadFile", {
                callback: (res:any) => {
                  resolve(res);
                },
                request: {resourceUid:document.file_resourceuid}
              });
          });
    if (downloadDocument.content) {
          this.printDocumentResonse(downloadDocument)
    }
}


printDocumentResonse(documentUploadResponse: any) {
    if (documentUploadResponse) {
      const {content, format} = documentUploadResponse;
      const byteCharacters = atob(content);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], {type: format});

            const blobUrl = URL.createObjectURL(blob);

            // Crée dynamiquement un iframe
            const iframe = document.createElement('iframe');
            iframe.style.display = 'none';
            document.body.appendChild(iframe);

            iframe.src = blobUrl;

            iframe.onload = function() {
                iframe?.contentWindow?.focus();
                iframe.contentWindow?.print();

            };
    }
}

async viewDocument (document: any) {
    const downloadDocument :any = await new Promise((resolve) => {
              store.dispatch("taskModule/uploadDocumentModule/downloadFile", {
                callback: (res:any) => {
                  resolve(res);
                },
                request: {resourceUid:document.file_resourceuid}
              });
    });
  if (downloadDocument.content) {
      const { format, content } = downloadDocument
      openFileInNewTab({ name: downloadDocument.originalFileName, mimeType: format, base64: content });
  } 
}


async created() {
    this.isLoading = true
    if (store.state.summaryPanelModule.order?.resourceUid) {
        await store.dispatch("orderModule/getOrderDeletedDocuments", {
            filter: {
                orderResourceUid: store.state.summaryPanelModule.order?.resourceUid
            },
        });
        this.isLoading = false
    }
    else {
      this.isLoading = false
    }
  }
}
