import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card-body p-0" }
const _hoisted_2 = { class: "font-size-12 font-medium" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_th = _resolveComponent("q-th")!
  const _component_q_tr = _resolveComponent("q-tr")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_OrderHistoriesPanel = _resolveComponent("OrderHistoriesPanel")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_StatusOrderHistory = _resolveComponent("StatusOrderHistory")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_q_table, {
      class: "shadow-0 table bg-white mb-0",
      bordered: "",
      rows: _ctx.historiesStep,
      "row-key": "taskId",
      "rows-per-page-options": [_ctx.historiesStep?.length],
      "hide-pagination": ""
    }, {
      header: _withCtx((props) => [
        _createVNode(_component_q_tr, { props: props }, {
          default: _withCtx(() => [
            _createVNode(_component_q_th, { "auto-width": "" }),
            _createVNode(_component_q_th, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("task.historiesStep.headers.action")), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_q_th, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("task.historiesStep.headers.creationDate")), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_q_th, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("task.historiesStep.headers.status")), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_q_th, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("task.historiesStep.headers.date")), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_q_th, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("task.historiesStep.headers.owner")), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_q_th, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("task.historiesStep.headers.variables.variables")), 1)
              ]),
              _: 1
            })
          ]),
          _: 2
        }, 1032, ["props"])
      ]),
      body: _withCtx((props) => [
        _createVNode(_component_q_tr, { props: props }, {
          default: _withCtx(() => [
            _createVNode(_component_q_td, { "auto-width": "" }, {
              default: _withCtx(() => [
                _createVNode(_component_q_btn, {
                  class: "btn btn-sm btn-primary",
                  size: "sm",
                  color: "accent",
                  round: "",
                  dense: "",
                  onClick: ($event: any) => (props.expand = !props.expand),
                  icon: props.expand ? 'remove' : 'add'
                }, null, 8, ["onClick", "icon"])
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_q_td, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(`${_ctx.$t(`task.actions.${props.row.taskDefinition}`)} `), 1)
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_q_td, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.getDateFormated(`${props.row.creationDate || ""} `)), 1)
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_q_td, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(props.row.status ? `${_ctx.$t(`task.historiesStep.headers.histories.${props.row.status}`)}` : "-"), 1)
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_q_td, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(props.row.endDate ? _ctx.getDateFormated(`${props.row.endDate || ""} `) : "-"), 1)
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_q_td, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.getName(`${props.row.assignee || ""} `)), 1)
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_q_td, { "auto-width": "" }, {
              default: _withCtx(() => [
                _createVNode(_component_q_btn, {
                  class: "btn btn-sm btn-primary",
                  size: "sm",
                  color: "accent",
                  round: "",
                  dense: "",
                  onClick: ($event: any) => (_ctx.openStatusHistory(props.row)),
                  icon: "visibility"
                }, null, 8, ["onClick"])
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1032, ["props"]),
        _withDirectives(_createVNode(_component_q_tr, {
          props: props,
          class: "tw-expanded-table-row"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_td, { colspan: "100%" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createVNode(_component_OrderHistoriesPanel, {
                    histories: props.row.histories
                  }, null, 8, ["histories"])
                ])
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1032, ["props"]), [
          [_vShow, props.expand]
        ])
      ]),
      "no-data": _withCtx(({ icon, message, filter }) => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t("task.historiesStep.headers.histories.noDataMessage")), 1)
        ])
      ]),
      _: 1
    }, 8, ["rows", "rows-per-page-options"]),
    _createElementVNode("div", null, [
      _createVNode(_component_StatusOrderHistory)
    ])
  ], 64))
}