
import {Options, Vue} from "vue-class-component";
import store from "@/store";
import DocumentsHistoryDialog from "@/components/alert/DocumentsHistory/DocumentsHistoryDialog.vue";

@Options({
  components: { DocumentsHistoryDialog }
})
export default class DocumentsHistory extends Vue {

   toggleShowControls() {
    return store.dispatch('orderModule/toggleShowDocumentsHistory')
  }

  get toggleShow(){
  return store.state.orderModule.showDocumentsHistory
}



}
