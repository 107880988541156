import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3203eb14"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tw-notfication-actions" }
const _hoisted_2 = { class: "align-items-center d-flex" }
const _hoisted_3 = { class: "font-size-14 font-medium" }
const _hoisted_4 = {
  key: 0,
  class: "badge badge-danger ml-1 p-1 small",
  style: {"font-size":"10px"}
}
const _hoisted_5 = { class: "mb-2" }
const _hoisted_6 = {
  key: 0,
  class: "text-muted"
}
const _hoisted_7 = { class: "font-medium" }
const _hoisted_8 = { class: "mb-2" }
const _hoisted_9 = {
  key: 0,
  class: "badge badge-warning"
}
const _hoisted_10 = { class: "badge bg-grey-12 mr-2g" }
const _hoisted_11 = {
  key: 0,
  class: "bg-white p-2 border default-border-radius tw-notification-comment-wrapper"
}
const _hoisted_12 = {
  key: 1,
  class: "bg-white default-border-radius p-1 pl-0 pr-0"
}
const _hoisted_13 = { class: "d-flex justify-content-end align-items-center" }
const _hoisted_14 = {
  key: 2,
  class: "bg-white p-1 default-border-radius"
}
const _hoisted_15 = { class: "row form-group" }
const _hoisted_16 = { class: "col-lg-12 col-md-12 col-sm-12 form-control no-border font-size-12" }
const _hoisted_17 = { class: "d-flex justify-content-end align-items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_combo_recipients = _resolveComponent("combo-recipients")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["tw-notification-container border-bottom p-3", 'tw-notification-status-' + _ctx.notification.status.resourceUid])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", {
          class: "p-0 text-primary bg-transparent cursor-pointer",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showTransfer = !_ctx.showTransfer))
        }, _toDisplayString(_ctx.$t("communication.comNotification.transferBtn")), 1),
        _createElementVNode("span", {
          class: "p-0 text-primary bg-transparent cursor-pointer",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (
          (_ctx.showRepondre = !_ctx.showRepondre),
            (_ctx.msgUID = _ctx.notification.resourceUid),
            (_ctx.sender = _ctx.notification.sender),
            (_ctx.priority = _ctx.notification.priority),
            (_ctx.subject = _ctx.notification.subject),
            (_ctx.type = _ctx.notification.type),
            (_ctx.senderUid = _ctx.notification.senderUid),
            (_ctx.reference = _ctx.notification.reference),
            (_ctx.metadata = _ctx.notification.metadata)
        ))
        }, _toDisplayString(_ctx.$t("communication.comNotification.respondBtn")), 1),
        _createElementVNode("span", {
          class: "p-0 text-primary bg-transparent cursor-pointer",
          onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.updateState({ index: _ctx.index, notification: _ctx.notification, ActionType: 'Delete' })), ["stop"]))
        }, _toDisplayString(_ctx.$t("communication.comNotification.deleteBtn")), 1)
      ]),
      _createElementVNode("div", {
        class: "tw-notfication-content",
        onClick: _cache[12] || (_cache[12] = _withModifiers(($event: any) => (_ctx.updateState({ index: _ctx.index, notification: _ctx.notification, ActionType: 'ChangeStatus' })), ["stop"]))
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.getNotification().subject?.resourceUid), 1),
          (_ctx.getNotification().priority)
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, " Important "))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_5, [
          (_ctx.getNotification().communicationDate)
            ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.formateCommunicationDate) + " - ", 1))
            : _createCommentVNode("", true),
          _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.getNotification().sender), 1)
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("span", {
            class: "badge bg-grey-12 mr-2",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.openNewTab(_ctx.entityReference, _ctx.statusUID ,_ctx.context)))
          }, _toDisplayString(_ctx.entityReference), 1),
          (_ctx.checKContextEntityStatus)
            ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.entityStatus), 1))
            : _createCommentVNode("", true),
          _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.context), 1)
        ]),
        (!_ctx.showRepondre && !_ctx.showTransfer)
          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
              _createElementVNode("span", {
                class: _normalizeClass(["d-flex", _ctx.showComment ? '' : 'tw-trancate-text-comment'])
              }, _toDisplayString(_ctx.getNotification().body), 3),
              _createElementVNode("span", null, [
                _createElementVNode("button", {
                  class: "btn p-0 bg-transparent text-primary",
                  style: {"text-decoration":"underline !important"},
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showComment = !_ctx.showComment))
                }, _toDisplayString(!_ctx.showComment
                ? _ctx.$t("communication.comNotification.showMore")
                : _ctx.$t("communication.comNotification.showLess")), 1)
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.showRepondre && !_ctx.showTransfer)
          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
              _createElementVNode("form", {
                onSubmit: _cache[6] || (_cache[6] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitFormTransfert && _ctx.submitFormTransfert(...args)), ["prevent"]))
              }, [
                _withDirectives(_createElementVNode("textarea", {
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.msgSend) = $event)),
                  class: "w-100 border"
                }, null, 512), [
                  [_vModelText, _ctx.msgSend]
                ])
              ], 32),
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("button", {
                  class: "btn bg-transparent text-dark mr-2",
                  type: "button",
                  onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.cancelFormRepondre && _ctx.cancelFormRepondre(...args))),
                  filled: ""
                }, _toDisplayString(_ctx.$t("communication.comNotification.cancelBtn")), 1),
                _createElementVNode("button", {
                  class: "btn btn-primary",
                  type: "submit",
                  onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.messageReplyMethode && _ctx.messageReplyMethode(...args)))
                }, _toDisplayString(_ctx.$t("communication.comNotification.sendBtn")), 1)
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.showTransfer)
          ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
              _createElementVNode("form", {
                onSubmit: _cache[9] || (_cache[9] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitFormRepondre && _ctx.submitFormRepondre(...args)), ["prevent"]))
              }, [
                _createElementVNode("div", _hoisted_15, [
                  _createVNode(_component_combo_recipients, {
                    ref: "comboRecipients",
                    recipients: _ctx.destinataires,
                    metadata: _ctx.notification.metadata
                  }, null, 8, ["recipients", "metadata"]),
                  _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.$t("communication.comNotification.valueOfRecipient")) + ": ", 1)
                ])
              ], 32),
              _createElementVNode("div", _hoisted_17, [
                _createElementVNode("button", {
                  class: "btn bg-transparent text-dark mr-2",
                  type: "reset",
                  onClick: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.cancelFormTransfert && _ctx.cancelFormTransfert(...args)))
                }, _toDisplayString(_ctx.$t("communication.comNotification.cancelBtn")), 1),
                _createElementVNode("button", {
                  class: "btn btn-primary",
                  type: "submit",
                  onClick: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_ctx.submitFormTransfert && _ctx.submitFormTransfert(...args)))
                }, _toDisplayString(_ctx.$t("communication.comNotification.sendBtn")), 1)
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ], 2),
    _createVNode(_component_q_inner_loading, {
      showing: _ctx.showInner,
      label: _ctx.$t('communication.comMessage.waitSendMessageLabel'),
      "label-class": "text-blue",
      "label-style": "font-size: 1.1em"
    }, null, 8, ["showing", "label"])
  ], 64))
}