import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-836c3a28"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "calculation-spinner flex flex-center"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = {
  key: 0,
  class: "text-deep-black-4 font-weight-normal mt-3"
}
const _hoisted_4 = {
  key: 1,
  class: "row"
}
const _hoisted_5 = { class: "col-12" }
const _hoisted_6 = { class: "card-body pl-2 pr-2 pt-1 pb-0 mb-2" }
const _hoisted_7 = {
  key: 0,
  class: "row mt-2 mb-2"
}
const _hoisted_8 = { class: "col-lg-4 d-flex flex-column" }
const _hoisted_9 = { class: "d-block mb-0 ml-3 text-muted" }
const _hoisted_10 = { class: "ml-3" }
const _hoisted_11 = { class: "cursor-pointer tw-ellipses-text" }
const _hoisted_12 = { class: "col-lg-2 d-flex flex-column" }
const _hoisted_13 = { class: "d-block mb-0 ml-3 text-muted" }
const _hoisted_14 = { class: "ml-3" }
const _hoisted_15 = { class: "cursor-pointer tw-ellipses-text" }
const _hoisted_16 = { class: "col-lg-3 d-flex flex-column" }
const _hoisted_17 = { class: "d-block mb-0 ml-3 text-muted" }
const _hoisted_18 = { class: "ml-3" }
const _hoisted_19 = { class: "cursor-pointer tw-ellipses-text" }
const _hoisted_20 = { class: "col-lg-3 text-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_spinner_facebook = _resolveComponent("q-spinner-facebook")!
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_ctx.isLoading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_q_spinner_facebook, {
          size: "3em",
          color: "primary"
        })
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        (_ctx.selectedObject && _ctx.selectedObject.length ===0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t("order.orderActions.NoDocumentsFound")), 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.selectedObject && _ctx.selectedObject.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedObject, (value, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "tw-dashed-border-secondary custom-border mt-2",
                        key: index
                      }, [
                        value
                          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                              _createElementVNode("div", _hoisted_8, [
                                _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t('demand.documents.columns.type')), 1),
                                _createElementVNode("span", _hoisted_10, [
                                  _createElementVNode("a", _hoisted_11, _toDisplayString(_ctx.documentType(value)), 1)
                                ])
                              ]),
                              _createElementVNode("div", _hoisted_12, [
                                _createElementVNode("label", _hoisted_13, _toDisplayString(_ctx.$t('demand.documents.columns.date')), 1),
                                _createElementVNode("span", _hoisted_14, [
                                  _createElementVNode("a", _hoisted_15, _toDisplayString(_ctx.getDateFormat(value._when_created)), 1)
                                ])
                              ]),
                              _createElementVNode("div", _hoisted_16, [
                                _createElementVNode("label", _hoisted_17, _toDisplayString(_ctx.$t('demand.documents.columns.user')), 1),
                                _createElementVNode("span", _hoisted_18, [
                                  _createElementVNode("a", _hoisted_19, _toDisplayString(value._who_created), 1)
                                ])
                              ]),
                              _createElementVNode("div", _hoisted_20, [
                                value
                                  ? (_openBlock(), _createBlock(_component_q_btn, {
                                      key: 0,
                                      outline: "",
                                      class: "btn btn-action",
                                      style: {"margin-right":"0.2em"},
                                      icon: "visibility",
                                      onClick: ($event: any) => (_ctx.viewDocument(value))
                                    }, null, 8, ["onClick"]))
                                  : _createCommentVNode("", true),
                                value
                                  ? (_openBlock(), _createBlock(_component_q_btn, {
                                      key: 1,
                                      outline: "",
                                      class: "btn btn-action",
                                      style: {"margin-right":"0.2em"},
                                      icon: "download",
                                      onClick: ($event: any) => (_ctx.downloadDocument(value))
                                    }, null, 8, ["onClick"]))
                                  : _createCommentVNode("", true),
                                value
                                  ? (_openBlock(), _createBlock(_component_q_btn, {
                                      key: 2,
                                      style: {"margin-right":"0.2em"},
                                      outline: "",
                                      class: "btn btn-action",
                                      icon: "print",
                                      onClick: ($event: any) => (_ctx.printDocument(value))
                                    }, null, 8, ["onClick"]))
                                  : _createCommentVNode("", true)
                              ])
                            ]))
                          : _createCommentVNode("", true)
                      ]))
                    }), 128))
                  ])
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ]))
}