import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f6918572"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "tw-document-wrapper z-top controls-background" }
const _hoisted_3 = { class: "card-header bg-white d-flex justify-content-between align-items-center pl-3 pr-2" }
const _hoisted_4 = { class: "card-title mb-0 d-flex align-items-center" }
const _hoisted_5 = { class: "font-weight-bold" }
const _hoisted_6 = { class: "card-title mb-0 d-flex align-items-end" }
const _hoisted_7 = { class: "row card-body tw-document-container" }
const _hoisted_8 = {
  key: 0,
  class: "tw-global-spinner z-top position-absolute"
}
const _hoisted_9 = {
  key: 1,
  class: "col-12"
}
const _hoisted_10 = {
  key: 0,
  class: "text-deep-black-4 font-weight-normal mt-3"
}
const _hoisted_11 = {
  key: 1,
  class: "col-12 pr-2 pl-2 pt-2 default-border-radius"
}
const _hoisted_12 = {
  key: 0,
  class: "row"
}
const _hoisted_13 = { class: "col-lg-3 d-flex flex-column" }
const _hoisted_14 = { class: "d-block mb-0 ml-3 text-muted" }
const _hoisted_15 = { class: "ml-3" }
const _hoisted_16 = { class: "cursor-pointer tw-ellipses-text" }
const _hoisted_17 = { class: "col-lg-3 d-flex flex-column" }
const _hoisted_18 = { class: "d-block mb-0 ml-3 text-muted" }
const _hoisted_19 = { class: "ml-3" }
const _hoisted_20 = { class: "cursor-pointer tw-ellipses-text" }
const _hoisted_21 = { class: "col-lg-2 d-flex flex-column" }
const _hoisted_22 = { class: "d-block mb-0 ml-3 text-muted" }
const _hoisted_23 = { class: "ml-3" }
const _hoisted_24 = { class: "cursor-pointer tw-ellipses-text" }
const _hoisted_25 = { class: "col-lg-2 d-flex flex-column" }
const _hoisted_26 = { class: "d-block mb-0 ml-3 text-muted" }
const _hoisted_27 = { class: "ml-3" }
const _hoisted_28 = { class: "cursor-pointer tw-ellipses-text" }
const _hoisted_29 = { class: "col-lg-2 text-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_spinner_facebook = _resolveComponent("q-spinner-facebook")!
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_ctx.toggleShow)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("span", _hoisted_5, _toDisplayString(`${_ctx.$t("printDocument.reference")}: ${_ctx.reference}`), 1)
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_q_icon, {
                  name: "chevron_right",
                  size: "20px",
                  onClick: _ctx.toggleShowControls
                }, null, 8, ["onClick"])
              ])
            ]),
            _createElementVNode("div", _hoisted_7, [
              (_ctx.isLoading === true)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _createVNode(_component_q_spinner_facebook, {
                      size: "3em",
                      color: "primary"
                    })
                  ]))
                : _createCommentVNode("", true),
              (_ctx.isLoading === false)
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    (_ctx.selectedObject && _ctx.selectedObject.length ===0)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                          _createElementVNode("span", null, _toDisplayString(_ctx.$t("order.orderActions.NoDocumentsFound")), 1)
                        ]))
                      : _createCommentVNode("", true),
                    (_ctx.selectedObject && _ctx.selectedObject.length)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedObject, (value, index) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: index,
                              class: "col-12 p-2 mb-2 custom-border"
                            }, [
                              value
                                ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                                    _createElementVNode("div", _hoisted_13, [
                                      _createElementVNode("label", _hoisted_14, _toDisplayString(_ctx.$t('demand.documents.columns.document')), 1),
                                      _createElementVNode("span", _hoisted_15, [
                                        _createElementVNode("a", _hoisted_16, _toDisplayString(value.original_file_name), 1)
                                      ])
                                    ]),
                                    _createElementVNode("div", _hoisted_17, [
                                      _createElementVNode("label", _hoisted_18, _toDisplayString(_ctx.$t('demand.documents.columns.type')), 1),
                                      _createElementVNode("span", _hoisted_19, [
                                        _createElementVNode("a", _hoisted_20, _toDisplayString(_ctx.documentType(value)), 1)
                                      ])
                                    ]),
                                    _createElementVNode("div", _hoisted_21, [
                                      _createElementVNode("label", _hoisted_22, _toDisplayString(_ctx.$t('demand.documents.columns.date')), 1),
                                      _createElementVNode("span", _hoisted_23, [
                                        _createElementVNode("a", _hoisted_24, _toDisplayString(_ctx.getDateFormat(value._when_created)), 1)
                                      ])
                                    ]),
                                    _createElementVNode("div", _hoisted_25, [
                                      _createElementVNode("label", _hoisted_26, _toDisplayString(_ctx.$t('demand.documents.columns.user')), 1),
                                      _createElementVNode("span", _hoisted_27, [
                                        _createElementVNode("a", _hoisted_28, _toDisplayString(value._who_created), 1)
                                      ])
                                    ]),
                                    _createElementVNode("div", _hoisted_29, [
                                      value
                                        ? (_openBlock(), _createBlock(_component_q_btn, {
                                            key: 0,
                                            outline: "",
                                            class: "btn btn-action",
                                            style: {"margin-right":"0.2em"},
                                            icon: "visibility",
                                            onClick: ($event: any) => (_ctx.viewDocument(value))
                                          }, null, 8, ["onClick"]))
                                        : _createCommentVNode("", true),
                                      value
                                        ? (_openBlock(), _createBlock(_component_q_btn, {
                                            key: 1,
                                            outline: "",
                                            class: "btn btn-action",
                                            style: {"margin-right":"0.2em"},
                                            icon: "download",
                                            onClick: ($event: any) => (_ctx.downloadDocument(value))
                                          }, null, 8, ["onClick"]))
                                        : _createCommentVNode("", true),
                                      value
                                        ? (_openBlock(), _createBlock(_component_q_btn, {
                                            key: 2,
                                            style: {"margin-right":"0.2em"},
                                            outline: "",
                                            class: "btn btn-action",
                                            icon: "print",
                                            onClick: ($event: any) => (_ctx.printDocument(value))
                                          }, null, 8, ["onClick"]))
                                        : _createCommentVNode("", true)
                                    ])
                                  ]))
                                : _createCommentVNode("", true)
                            ]))
                          }), 128))
                        ]))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true)
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}