
import { Options, Vue } from "vue-class-component";
import OrderHistoriesPanel from "@/components/order/history/OrderHistoryPanel.vue";
import StatusOrderHistory from "@/components/order/history/StatusOrderHistory.vue";
import LabelAndValue from "@/components/general/LabelAndValue.vue";
import store from "@/store";
import moment from "moment";
import { OrderStatusHistoryVariables } from "@/types";



@Options({
  components: { OrderHistoriesPanel, LabelAndValue, StatusOrderHistory },
  props: ["historiesStep"],
})

export default class OrderHistory extends Vue {
  opened = false;
  historiesStep?: [];

  getDateFormated(date: any) {
    if (date && date !== "") return moment(date).format("DD/MM/YYYY HH:mm");
  }

  getName(value: string) {
    if (value && value !== "") {
      return value.split("##")[0];
    }
  }

  openStatusHistory(row: any) {
    store.dispatch("orderModule/setOrderVariablesVisible", true);
    console.table(row.variables)

    if (typeof row.variables === 'string') {
      const obj = JSON.parse(row.variables);
      const variablesSelected: OrderStatusHistoryVariables = {
        orderReference: obj.orderReference ? obj.orderReference.value:'',
        orderStatus: obj.orderStatus ? obj.orderStatus.value:'',
        customerType: obj.customerType ? obj.customerType.value:'',
        customerName: obj.customerName? obj.customerName.value:'',
        initialDeliveryDate: obj.order && obj.order.initialDeliveryDate ? obj.order.initialDeliveryDate.value:'',
        provisionalDeliveryDate: obj.order && obj.order.initialDeliveryDate ? obj.order.provisionalDeliveryDate.value:'',
        taskDefinition: row.taskDefinition,
        comment : obj.comment && obj.comment.value ? obj.comment.value : ''
      }

      store.dispatch("orderModule/setOrderStatusHistoryVariablesSelected", variablesSelected);
    }

  }

  created() {
    store.dispatch("orderModule/getOrderHistory");
  }

}
