import i18n from "@/i18n";
import store from "@/store";
import { PDFDocument } from "pdf-lib";
import Notify from 'quasar/src/plugins/Notify.js';;

interface FileOptions {
    mimeType: string,
    base64: string,
    name: string
}

/**
 * Sets the isLoading property of the row object to true and dispatches an action to download a file.
 * @param {any} row - The row object representing a document.
 */
export const viewDocument = (row: any) => {
    row.isLoading = true
    store.dispatch('taskModule/uploadDocumentModule/downloadFile', {
        request: row.document,
        row: row,
        callback: viewDocumentResponse
    })
}
export const viewDocumentUploadPanel = (file: any) => {
    openFileInNewTab({ name: file.originalFileName, mimeType: file.format, base64: file.content });
}
export const viewDocumentOrder = (row: any) => {
    row.isLoading = true
    store.dispatch('taskModule/uploadDocumentModule/downloadFile', {
        request: row.documentUpload,
        row: row,
        callback: viewDocumentResponse
    })
}
export const viewDocumentScannedOrder = (row: any) => {
    store.dispatch('taskModule/uploadDocumentModule/downloadFile', {
        request: row,
        row: row,
        callback: viewDocumentResponse
    })
}
export const viewDocumentParty = (row: any) => {
    row.isLoading = true
    store.dispatch('taskModule/uploadDocumentModule/downloadFile', {
        request: row,
        row: row,
        callback: viewDocumentResponse
    })
}


export const printDocumentResonse =(documentUploadResponse: any) => {
    if (documentUploadResponse) {
      const {content, format} = documentUploadResponse;
      const byteCharacters = atob(content);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], {type: format});

            const blobUrl = URL.createObjectURL(blob);

            // Crée dynamiquement un iframe
            const iframe = document.createElement('iframe');
            iframe.style.display = 'none';
            document.body.appendChild(iframe);

            iframe.src = blobUrl;

            iframe.onload = function() {
                iframe?.contentWindow?.focus();
                iframe.contentWindow?.print();

            };
        }
  }
/**
 * Handles the response after downloading the file and opens it in a new tab if successful.
 * @param {any} response - The response object containing the file information.
 * @param {any} row - The row object representing a document.
 */
const viewDocumentResponse = async (response: any, row: any) => {
    if (response) {
        const { format, content } = response
        openFileInNewTab({ name: response.originalFileName, mimeType: format, base64: content }, row.documentType);
        if (row)
            row.isLoading = false;
    }
}
export let documentWindow: Window | null = null;

/**
 * Opens the file in a new browser tab.
 * @param {Object} fileOptions - The file options containing the MIME type, base64 content, and name of the file.
 */
export const openFileInNewTab = async (fileOptions: FileOptions, documentType?: string) => {
    const {
        mimeType,
        base64,
        name
    } = fileOptions

    let updatedBase64 = base64;
    if (mimeType === 'application/pdf') {
        const title = documentType ? documentType.replace(/_/g, ' ') : name;
        updatedBase64 = await updatePdfMetadata(base64, title);
    }

    const linkSource = `data:${mimeType};base64,${updatedBase64}`;
    //const newWindow = window.open("");
    if (!documentWindow || documentWindow.closed) {
        documentWindow = window.open("");
    }
     /*if (newWindow) {
        newWindow.document.write("<object type='" + mimeType + "' width='100%' height='100%' data='" + linkSource + "'></object>");
        newWindow.document.title = name;

    }*/
   if (documentWindow) {
    // Met à jour le contenu de la fenêtre
    documentWindow.focus();
    documentWindow.document.body.innerHTML = `<object type='${mimeType}' width='100%' height='100%' data='${linkSource}'></object>`;
    if(documentType !== undefined) {
        let _documentType = documentType.replace(/_/g, ' ');
        documentWindow.document.title = `${_documentType}`;
    } else {
        documentWindow.document.title = name;
    }
    Notify.create({
        message: i18n.global.t('order.messages.fileLoadedSuccess'),
        color: 'positive'
      });
    }
}

async function updatePdfMetadata(base64: string, title: string): Promise<string> {
    try {
        /*const pdfBytes = Uint8Array.from(atob(base64), c => c.charCodeAt(0));
        const pdfDoc = await PDFDocument.load(pdfBytes);

        // Modifier le titre du PDF
        pdfDoc.setTitle(title);

        // Sauvegarder les modifications
        const updatedPdfBytes = await pdfDoc.save();
        return btoa(String.fromCharCode(...updatedPdfBytes));*/
         // Convertir base64 en Uint8Array
         const pdfBytes = base64ToUint8Array(base64);

         // Charger le PDF
         const pdfDoc = await PDFDocument.load(pdfBytes);
 
         // Modifier le titre du PDF
         pdfDoc.setTitle(title);
 
         // Sauvegarder les modifications
         const updatedPdfBytes = await pdfDoc.save();
 
         // Convertir Uint8Array en base64
         return uint8ArrayToBase64(updatedPdfBytes);

    } catch (error) {
        console.error("Failed to update PDF metadata:", error);
        return base64; // Retourne le PDF original en cas d'erreur
    }
}
function base64ToUint8Array(base64: string): Uint8Array {
    const binaryString = atob(base64);
    const length = binaryString.length;
    const bytes = new Uint8Array(length);
    for (let i = 0; i < length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
}

function uint8ArrayToBase64(bytes: Uint8Array): string {
    let binary = '';
    const length = bytes.byteLength;
    for (let i = 0; i < length; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
}