
import { ref } from 'vue';
import {Options, Vue } from 'vue-class-component';

import SummaryMessage from "@/components/summary-panel/SummaryMessage.vue";

import { useRoute } from 'vue-router';
import OrderHistory from '@/components/order/history/OrderHistory.vue';
import OrderSummaryDocuments from './OrderSummaryDocuments.vue';
@Options({
  components: { SummaryMessage,OrderHistory, OrderSummaryDocuments},
  props: ["entityState", "historiesStep"],
})

export default class OrderSummaryHistory extends Vue {
  activeTab = ref('actions');
  entityState?: any;
  historiesStep?: any;

  isMo() {
    return this.currentRoute !== 'TaskDetail';

  }
  get currentRoute() {
    return useRoute().name;
  }
}
